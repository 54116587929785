import React from "react";

const Footer = () => {
  return (
    <footer className="py-5" style={styles.footer}>
      <div className="containerD">
        <div className="row">
          <div className="col">
            <img
              className="w-75"
              src={require("src/assets/donation/logo.png").default}
              alt=""
            />
            <input
              className="newsInput"
              type="email"
              id="SubscribeNewsletter"
              placeholder="Subscribe for newsletter"
              name="SubscribeNewsletter"
            />
            <div id="subscribeButton" style={styles.newsBtn}>
              SUBMIT
            </div>
          </div>
          <div className="col">
            <div style={styles.footerTitle}>Contacts</div>
            <div style={styles.footerText}>
              G2, Max Towers, Delhi One, <br />
              Sector-16 B, Gautam Buddh Nagar,
              <br />
              Noida, Uttar Pradeh-201301, India
            </div>
            <br />
            <div style={styles.footerText}>Phone: +31 85 964 47 25</div>
            <br />
            <div style={styles.footerText}>Email: info@routes2roots.com</div>
          </div>
          <div className="col">
            <div style={styles.footerTitle}>Menu & Links</div>
            <div className="row">
              <div className="col">
                <div className="footerTextHover" style={styles.footerText}>
                  Home
                </div>
                <br />
                <div className="footerTextHover" style={styles.footerText}>
                  About Us
                </div>
                <br />
                <div className="footerTextHover" style={styles.footerText}>
                  Impact
                </div>
                <br />
                <div className="footerTextHover" style={styles.footerText}>
                  Media center
                </div>
                <br />
              </div>
              <div className="col">
                <div className="footerTextHover" style={styles.footerText}>
                  Donate
                </div>
                <br />
                <div className="footerTextHover" style={styles.footerText}>
                  Contact US
                </div>
                <br />
              </div>
            </div>
          </div>
          <div className="col d-flex flex-column">
            <div style={styles.footerTitle}>Donate</div>
            <div style={styles.footerText}>
              Help Us Change the Lives of Children in World
            </div>
            <div style={styles.donateBtn}>DONATE</div>
            <div className="d-flex w-100 justify-content-evenly">
              <img
                style={styles.socialIcon}
                src={require("src/assets/donation/facebook.png").default}
                alt="facebook"
              />
              <img
                src={require("src/assets/donation/twitter.png").default}
                alt="Twitter"
              />
              <img
                src={require("src/assets/donation/linkedin.png").default}
                alt="linkedin"
              />
              <img
                src={require("src/assets/donation/youtube.png").default}
                alt="Youtube"
              />
              <img
                src={require("src/assets/donation/insta.png").default}
                alt="Instagram"
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div style={styles.footerSText}>@2024 Routes2Roots</div>
          <div style={styles.footerSText}>Term and Condition</div>
        </div>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: "#20212b",
    lineHeight: "32px",
    color: "#a9a9ab",
  },
  footerSText: {
    fontSize: "0.7rem",
  },
  newsBtn: {
    width: "50%",
    textAlign: "center",
    padding: "0.2rem 1.2rem",
    fontWeight: 700,
    fontSize: "0.9rem",
    cursor: "pointer",
    border: "2px solid #efc940",
    borderRadius: "50px",
  },
  footerTitle: {
    fontWeight: 700,
    fontSize: "20px",
    letterSpacing: "-1px",
    color: "#ffffff",
    marginBottom: "0.9rem",
  },
  footerText: {
    fontSize: "0.9rem",
    display: "inline",
    lineHeight: "27.2px",
    padding: "0.3rem 0rem",
  },
  donateBtn: {
    width: "100%",
    textAlign: "center",
    padding: "0.5rem 1rem",
    background: "#efc940",
    borderRadius: "50rem",
    margin: "1rem 0rem",
    fontWeight: 700,
    fontSize: "12px",
    cursor: "pointer",
    color: "#333333",
  },
  socialIcon: {
    width: "1.5rem",
    height: "1.5rem",
  },
};

export default Footer;
