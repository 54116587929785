import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import "./MoneySlider.css";

const DonationForm = () => {
  const [isIndian, setIsIndian] = useState(true);
  const [isRupee, setIsRupee] = useState(true);
  const [value, setValue] = useState(30000);
  const [selectedOption, setSelectedOption] = useState("Workshops");
  const marks = [5000, 10000, 15000, 25000, 50000, 75000, 100000];
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    panCardNumber: "",
    pincode: "",
    address: "",
    city: "",
    state: "",
    conditions: false,
  });

  const getProgress = () => {
    return value > 105000 ? 100 : (value / 105000) * 100;
  };

  const getMarkProgress = (mark) => {
    return (mark / 105000) * 100;
  };

  const handleChange = (event) => {
    setValue(parseInt(event.target.value ? event.target.value : 0));
  };

  const handleDotClick = (mark) => {
    setValue(mark);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form data
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.phoneNumber ||
      !formData.emailAddress ||
      !formData.conditions
    ) {
      alert("Please fill in all required fields and accept the terms.");
      return;
    }

    createRazorpayOrder();

    // Additional actions, like redirecting to a thank you page, can be added here.
  };

  const getMessage = () => {
    switch (selectedOption) {
      case "Workshops":
        return (
          <span>
            “
            <span style={{ color: "#00a651" }}>
              From {value.toLocaleString()}
            </span>
            we would be able to conduct {Math.floor(value / 15000)} interactive
            workshops, engaging hundreds of students in hands-on cultural
            learning experiences.”
          </span>
        );

      case "Installation of Digital Classroom":
        return (
          <span>
            “With{" "}
            <span style={{ color: "#00a651" }}>{value.toLocaleString()}</span>,
            we would be able to establish {Math.floor(value / 150000)} new
            digital smart classrooms, empowering teachers and students with
            modern technology”
          </span>
        );

      case "Classes":
        return (
          <span>
            “Investing{" "}
            <span style={{ color: "#00a651" }}>{value.toLocaleString()}</span>{" "}
            we would be able to provide interactive digital classes for{" "}
            {Math.floor(value / 50000)}
            schools, benefiting thousands of children with real-time learning.”
          </span>
        );

      case "Distribution of Musical Instruments":
        return (
          <span>
            “A donation of{" "}
            <span style={{ color: "#00a651" }}>{value.toLocaleString()}</span>{" "}
            we would be able to supply musical instruments to{" "}
            {Math.floor(value / 2000)} schools, nurturing creativity and talent
            in the students."”
          </span>
        );

      default:
        return (
          <span>
            “
            <span style={{ color: "#00a651" }}>
              From {value.toLocaleString()}
            </span>
            we would be able to conduct 2 interactive workshops, engaging
            hundreds of students in hands-on cultural learning experiences.”
          </span>
        );
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const createRazorpayOrder = () => {
    let body = JSON.stringify({
      // currency: isRupee ? "INR" : "",
      currency: "INR",
      nationality: isIndian ? "Indian" : "Foreign",
      donor_type: "Individual",
      name: formData.firstName + " " + formData.lastName,
      phone: formData.phoneNumber,
      email: formData.emailAddress,
      // country: isIndian ? "India" : "Foreign",
      country: "India",
      address: formData.address + ", " + formData.city + ", " + formData.state,
      website: "https://routes2roots.ngo/",
      purpose: "Social",
      amount: value,
      activity: "",
      project: "",
      panNo: formData.panCardNumber,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/api/v1/donation`,
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };

    axios
      .request(config)
      .then(function (response) {
        if (response.data.status) {
          handleRazorpayScreen(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Unexpected error occured");
      });
  };

  const handleRazorpayScreen = async (data) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Some error at Razorpay Screen Loading");
      return;
    }

    const options = {
      key: data.key,
      amount: data.amount,
      currency: "INR",
      name: data.name,
      description: "Donation For" + selectedOption,
      image: "https://s3.amazonaws.com/rzp-mobile/images/rzp.png",
      order_id: data.order_id,
      handler: function (response) {
        console.log(response);
      },
      prefill: {
        name: data.name,
        email: data.email,
        contact: data.contact,
      },
      theme: {
        color: "#0c238a",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <form className="w-100 d-flex flex-column align-items-center">
      {/* Citizenship selection */}
      <div style={styles.title} className="my-5">
        Select <span style={styles.titleSpan}>Citizenship</span>
      </div>
      <div className="d-flex justify-content-center gap-5 w-100">
        <div
          className="mx-5 d-flex gap-5 align-items-center"
          style={isIndian ? styles.citizenSelected : styles.citizen}
          onClick={() => setIsIndian(true)}
        >
          <div
            style={isIndian ? styles.citizenTextSelected : styles.citizenText}
          ></div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "24px",
              color: "#444444",
            }}
          >
            Indian Citizen
          </div>
        </div>
        <div
          className="mx-5 d-flex gap-5 align-items-center"
          style={isIndian ? styles.citizen : styles.citizenSelected}
          onClick={() => setIsIndian(false)}
        >
          <div
            style={isIndian ? styles.citizenText : styles.citizenTextSelected}
          ></div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "24px",
              color: "#444444",
            }}
          >
            Foreign National
          </div>
        </div>
      </div>

      {/* Donation For selection */}
      <div className="d-flex justify-content-center gap-5 w-100 my-5">
        <div style={styles.title}>
          Donation <span style={styles.titleSpan}>For</span>
        </div>
        <div
          style={{
            borderRadius: "50px",
            padding: "0rem 1rem",
            background: "#FFFFFF",
            boxShadow: "0px 4px 12px 0px #14142B1F",
          }}
        >
          <select
            name="donationFor"
            style={styles.FormSelect}
            value={selectedOption}
            onChange={handleSelectChange}
          >
            <option value="Workshop">Workshop</option>
            <option value="Classes">Classes</option>
            <option value="Distribution of Musical Instruments">
              Distribution of Musical Instruments
            </option>
            <option value="Installation of Digital Classroom">
              Installation of Digital Classroom
            </option>
          </select>
        </div>
      </div>

      {/* Money Slider */}

      <div className="slider-container">
        <input
          type="range"
          min="0"
          max={marks[marks.length - 1] + 5000}
          value={value}
          step="100"
          className="slider"
          onChange={handleChange}
          style={{
            background: `linear-gradient(to right, #f4c542 ${getProgress()}%, #fff ${getProgress()}%)`,
          }}
        />
        <div className="slider-values">
          <div className="relative">
            {marks.map((mark) => (
              <div
                key={mark}
                className="slider-mark"
                style={{
                  left: `${getMarkProgress(mark)}%`,
                }}
                onClick={() => handleDotClick(mark)}
              >
                <span className={`dot ${value >= mark ? "active" : ""}`}></span>
                <span className="mark-label">
                  {isRupee ? "Rs" : "$"} {mark.toLocaleString()}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div
          className="slider-tooltip"
          style={{
            left: `${getProgress()}%`,
          }}
        >
          <div className="slider-tooltip-text">
            {isRupee ? "Rs" : "$"} {value.toLocaleString()}
          </div>
          <div className="arrow-down"></div>
        </div>
      </div>
      <div
        className="d-flex align-items-center my-4"
        style={{
          borderRadius: "50px",
          backgroundColor: "#ffffff",
          boxShadow: "0px 4px 12px 0px #14142B1F",
        }}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            backgroundColor: "#f4c542",
            fontWeight: 800,
            fontSize: "2rem",
          }}
          onClick={() => {
            setIsRupee(!isRupee);
          }}
        >
          {isRupee ? "₹" : "$"}
        </div>
        <input
          style={styles.moneyInput}
          type="text"
          id="FirstName"
          placeholder="First Name"
          name="firstName"
          value={value}
          onChange={handleChange}
        />
      </div>
      {/* Message */}
      <img
        src={require("src/assets/donation/quotes.svg").default}
        alt="quotes logo"
        style={{
          Width: "2.5rem",
          margin: "2rem",
        }}
      />
      <div className="mb-5" style={styles.message}>
        {getMessage()}
      </div>

      {/* Personal Details */}
      <div
        className="mb-5"
        style={{
          backgroundColor: "#FFFFFF",
          maxWidth: "40rem",
          width: "100%",
          padding: "5rem 6rem",
          boxShadow: "0px 8px 12px 0px #14142B1F",
        }}
      >
        <div className="mb-5" style={styles.title}>
          Transform <span style={styles.titleSpan}>the lives of children</span>
        </div>
        <div style={styles.noteText}>Personal Details:</div>
        <div className="d-flex gap-4">
          <input
            style={styles.formInput}
            type="text"
            id="FirstName"
            placeholder="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
          />
          <input
            style={styles.formInput}
            type="text"
            id="LastName"
            placeholder="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </div>
        <div className="d-flex gap-4">
          <input
            style={styles.formInput}
            type="tel"
            id="PhoneNumber"
            placeholder="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
          <input
            style={styles.formInput}
            type="email"
            id="EmailAddress"
            placeholder="Email Address"
            name="emailAddress"
            value={formData.emailAddress}
            onChange={handleInputChange}
          />
        </div>
        <div className="d-flex">
          <input
            style={styles.formInput}
            className="w-100"
            type="text"
            id="PANCardNumber"
            placeholder="PAN Card Number"
            name="panCardNumber"
            value={formData.panCardNumber}
            onChange={handleInputChange}
          />
        </div>

        {/* Address */}
        <div style={styles.noteText}>Address:</div>
        <div className="d-flex">
          <input
            style={styles.formInput}
            className="w-100"
            type="text"
            id="Pincode"
            placeholder="Pincode"
            name="pincode"
            value={formData.pincode}
            onChange={handleInputChange}
          />
        </div>
        <div className="d-flex">
          <input
            style={styles.formInput}
            className="w-100"
            type="text"
            id="Address"
            placeholder="Address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
          />
        </div>
        <div className="d-flex gap-4">
          <input
            style={styles.formInput}
            type="text"
            id="City"
            placeholder="City"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
          />
          <input
            style={styles.formInput}
            type="text"
            id="State"
            placeholder="State"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
          />
        </div>

        {/* Terms and Conditions */}
        <div className="d-flex justify-content-center">
          <input
            type="checkbox"
            id="conditions"
            name="conditions"
            checked={formData.conditions}
            onChange={handleInputChange}
          />
          <label
            className="mx-3 conditions my-0"
            style={styles.conditions}
            htmlFor="conditions"
          >
            I have read through website’s{" "}
            <a style={styles.conditionsLink} href="/donation">
              Privacy Policies
            </a>{" "}
            &
            <a style={styles.conditionsLink} href="/donation">
              {" "}
              Terms And Conditions
            </a>{" "}
            to make a donation
          </label>
        </div>
        {/* Submit Button */}
        <div className="d-flex justify-content-center w-100">
          <button style={styles.donateBtn} onClick={handleSubmit}>
            DONATE
          </button>
          {/* <button style={styles.donateBtn} onClick={handleSubmit}>
            DONATE
          </button> */}
        </div>
      </div>
    </form>
  );
};

const styles = {
  citizen: {
    borderRadius: "50px",
    padding: "1rem 2rem",
    background: "#FFFFFF",
  },
  citizenSelected: {
    borderRadius: "50px",
    padding: "1rem 2rem",
    background: "#FFFFFF",
    boxShadow: "0px 4px 12px 0px #14142B1F",
  },
  citizenText: {
    width: "1rem",
    height: "1rem",
    borderRadius: "250px",
    background: "#EFC940",
    border: "2.5px solid #EFC940",
  },
  citizenTextSelected: {
    width: "1rem",
    height: "1rem",
    borderRadius: "250px",
    background: "#EFC940",
    border: "2.5px solid #252525",
  },
  FormSelect: {
    borderRadius: "50px",
    padding: "15px 42px 15px 40px",
    background: "#FFFFFF",
    color: "##444444",
    outline: "none",
    resize: "none",
    overflow: "hidden",
    fontWeight: 600,
    fontSize: "1rem",
    lineHeight: "24px",
    textAlign: "center",
    border: "1px solid var(--Grey-Heading, #25252500)",
  },
  formInput: {
    width: "100%",
    borderRadius: "50px",
    padding: "15px 42px 15px 40px",
    background: "#F9F7F6",
    color: "#000000",
    outline: "none",
    resize: "none",
    overflow: "hidden",
    fontWeight: 400,
    fontSize: "0.8rem",
    lineHeight: "20px",
    border: "1px solid var(--Grey-Heading, #25252500)",
    marginBottom: "1.5rem",
  },
  moneyInput: {
    maxWidth: "130px",
    width: "100%",
    borderRadius: "50px",
    padding: "20px 10px 20px 10px",
    background: "#FFFFFF",
    color: "#777777",
    outline: "none",
    resize: "none",
    overflow: "hidden",
    fontWeight: 600,
    fontSize: "1rem",
    lineHeight: "20px",
    border: "1px solid var(--Grey-Heading, #25252500)",
    textAlign: "center",
  },
  title: {
    fontSize: "40px",
    color: "#4a4c70",
    fontWeight: 700,
    lineHeight: "50px",
    letterSpacing: "-2px",
    textAlign: "center",
  },
  titleSpan: {
    fontWeight: 300,
  },
  noteText: {
    fontWeight: 700,
    fontSize: "1.2rem",
    lineHeight: "24px",
    letterSpacing: "-1px",
    color: "#4a4c70",
    marginBottom: "1.3rem",
  },
  message: {
    maxWidth: "60rem",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "34px",
    letterSpacing: "-0.02em",
    textAlign: "center",
  },
  conditions: {
    width: "54%",
    marginTop: "1.6rem",
    fontWeight: 700,
    fontSize: "0.8rem",
    color: "#777777",
    marginBottom: "1.8rem",
  },
  conditionsLink: {
    color: "#0029FF",
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  donateBtn: {
    border: "1px solid #EFC940",
    padding: "0.8rem 5rem",
    background: "#ffffff",
    borderRadius: "50px",
    fontWeight: 700,
    fontSize: "12px",
    cursor: "pointer",
    color: "#000000",
  },
};

export default DonationForm;
